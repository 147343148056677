class ourTeam {
    constructor() {
        this.setup();
    }
    setup() {
        
        this.categoryMain = '';
        if(document.querySelector('.container--team'))
        {
            this.categoryMain = document.querySelector('.container--team');
            for(let el of this.categoryMain.querySelectorAll('.team__row a')) {
                el.addEventListener('click',this.categoryDataFilter.bind(this));  
            }        
        }
    }
    categoryDataFilter(el) {
        
        for(let elfilter of this.categoryMain.querySelectorAll('.team__row a')) {
            elfilter.classList.remove('show')  
        }

        for(let catAll of this.categoryMain.querySelectorAll('.team__detail .team__detail__main')) {
           catAll.classList.add('hide');
            if(el.currentTarget.getAttribute('id') == catAll.getAttribute('data-categorytype')) {
                catAll.classList.remove('hide');
            }
        }
        el.currentTarget.classList.add('show');
    }
}

window.team = new ourTeam();