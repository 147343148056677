class FrontHome {
    constructor(){
        
    }
    setup(){

        this.mostViewDataXHR = {};
        this.mostViewContainer = '';
        this.searchValue = '';
        this.pageNumber = 1;

        this.searchUrl = '';
        this.searchTitle = '';
        this.searchPrivacyId = [];
        this.searchCountryId = [];
        this.searchDocumentType = '';
        this.searchFromYear = '';
        this.searchToyear = '';

        if(document.querySelector('.most-view-container'))
        {
            this.mostViewContainer = document.querySelector('.most-view-container');
            if(this.mostViewContainer.querySelector('.case-details')){
                this.caseDetailsClone = this.mostViewContainer.querySelector('.case-details').cloneNode(true);
                this.mostViewContainer.querySelector('.case-details').remove();
                this.mostViewCaseDetailsContainer = this.mostViewContainer.querySelector('.cases-list');
            } 

            if(document.querySelector('.banner__filter__search'))
            {
                document.querySelector('.banner__filter__search').addEventListener('click', this.searchPageRedirect.bind(this));
            }
            this.loadMostViewCases();
        }

        document.querySelector('#title').addEventListener('keyup', this.checkKeyCode.bind(this));
    }

    checkKeyCode(e)
    {
        if (e.keyCode === 13) 
        {
            this.searchPageRedirect(e);
        }
    }
    searchPageRedirect(e)
    {
        this.searchTitle = document.querySelector('#title').value;
        
        for (var option of document.querySelector('#privacyid').options) {
            if (option.selected) 
            {
                this.searchPrivacyId.push(option.value);
            }
        }

        for (var option of document.querySelector('#countryid').options) {
            if (option.selected) 
            {
                this.searchCountryId.push(option.value);
            }
        }

        this.searchDocumentType = document.querySelector('#documenttype').value;
        this.searchFromYear = document.querySelector('#fromyear').value;
        this.searchToyear = document.querySelector('#toyear').value;
        
        this.searchUrl = '?title='+this.searchTitle+'&privacyid='+this.searchPrivacyId+'&countryid='+this.searchCountryId+'&documenttype='+this.searchDocumentType+'&fromyear='+this.searchFromYear+'&toyear='+this.searchToyear+'';
        window.location.href = '/search'+this.searchUrl;
    }

    //City list Function start

    loadMostViewCases()
    {
        try{
            this.mostViewDataXHR.abort();
        } catch(e){

        }

        this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.remove('cases-list--skeleton-list--hide');

        this.mostViewDataXHR = new XMLHttpRequest();   // new HttpRequest instance 

        var POST_URL = '/api/mostviewcases';
        this.mostViewDataXHR.open("POST", POST_URL);
        this.mostViewDataXHR.setRequestHeader("Access-Control-Allow-Headers", "Origin,Content-Type,Accept");
        this.mostViewDataXHR.setRequestHeader('Cache-Control', 'no-cache');
        
        var formData  = new FormData();
          
        formData.append('platform','Website');
        formData.append('pagenumber',this.pageNumber);
        if(this.searchValue != '')
        {
            formData.append('searchvalue',this.searchValue);
        }
        else 
        {
            formData.append('searchvalue','');
        }

        let body = formData;
        this.mostViewDataXHR.onreadystatechange = function() {
            if (this.mostViewDataXHR.readyState === 4) {
                try {
                    //this.mostViewDataXHR.response // response
                    var r = JSON.parse(this.mostViewDataXHR.responseText);
                    if(r.status == 0)
                    {
                        if(r.data.length > 0)
                        {
                            this.fillCaseData(r);
                        }
                        else
                        {
                            this.mostViewCaseDetailsContainer.innerHTML = '<p class="feed-nomatches-cound">No matches.</p>';
                            this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.add('cases-list--skeleton-list--hide');
                        }
                    }
                    else
                    {
                        alert(r.message);
                        this.searchContainer.querySelector('.search-list--skeleton-list-container').classList.remove('search-result--skeleton-list--hide');
                    }

                } catch(error) {
                    console.error(error);
                }
            }
        }
        .bind(this);
        this.mostViewDataXHR.onabort = function() {   
        }
        this.mostViewDataXHR.send(body);
        return this.mostViewDataXHR;
    }

    fillCaseData(response)
    {
        
        for(const el of this.mostViewContainer.querySelectorAll('.case-details')){
            el.remove();
        }
        //this.mostViewCaseDetailsContainer.innerHTML = "";
    
        var c = 0;
        for(const caseData of response.data){
            if(caseData !== null){
                c++;
                if(c > 3){ continue; }
                const caseDataEl = this.caseDetailsClone.cloneNode(true);
                
                caseDataEl.setAttribute('href','/case/'+caseData.alias+'');
                if(caseData.coverimageurl != '')
                {
                    caseDataEl.setAttribute('class','most-view__row__box most-view__row__box--book-image');
                    caseDataEl.innerHTML = '<img src="'+caseData.coverimageurl+'" alt="">';
                }
                else
                {
                    caseDataEl.querySelector('.most-view__row__box__title').innerHTML = caseData.title;
                    caseDataEl.querySelector('.most-view__row__box__year--year').innerHTML = caseData.judgement_year+' - ';
                    caseDataEl.querySelector('.most-view__row__box__year--country').innerHTML = caseData.countryname;
                    caseDataEl.querySelector('.most-view__row__box__content').innerHTML = caseData.description;
                }
                
                this.mostViewCaseDetailsContainer.appendChild(caseDataEl);
            }
        }
        
        if(c == 0)
        {
            this.mostViewCaseDetailsContainer.innerHTML = '<p class="feed-nomatches-cound">No matches.</p>';
        }
        this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.add('cases-list--skeleton-list--hide');
    }

    //City list Function end
}
window.home = new FrontHome();