class CookieConsent {
    constructor(){

    }
    initialize(){

        if(document.querySelector('.cookie-consent')){
            document.querySelector('#acceptcookieconsent').addEventListener('click', this.acceptCookie.bind(this));
        }
    }

    acceptCookie(e){
        e.preventDefault();
        Util.api_call("/api/accept-cookie", {});
        document.querySelector('.cookie-consent').remove();
    }
}
var c = new CookieConsent();
export default c;