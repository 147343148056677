class FrontCommon {
    constructor(){
        this.setup();
    }
    setup(){
        
        this.newsEmailAddEditXHR = {};
        this.newsLetterContainer = '';

        document.addEventListener('DOMContentLoaded', function() {

            for(let el of document.querySelectorAll('.select-basic')) {
                new Choices(el , {
                    searchEnabled: false  
                });
            }   
            for(let el of document.querySelectorAll('.select-with-search')) {
                const choicesBasic = new Choices(el,{
                    removeItemButton: true
                });    
            }   
        });

        if(document.querySelector('.footer__col__newsletter'))
        {
            this.newsLetterContainer = document.querySelector('.footer__col__newsletter');
            this.newsLetterContainer.querySelector('#newsletteremailadd').addEventListener('click', this.newsletterEmailAddEdit.bind(this));
        }
    }

    newsletterEmailAddEdit(e)
    {
        var newsletteremailEl = this.newsLetterContainer.querySelector('#newsletteremail');
        
        
        if(newsletteremailEl.value == '' || !this.ValidateEmail(newsletteremailEl.value))
        {
            newsletteremailEl.setAttribute('data-invalid','');
        }
        else
        {
            try{
                this.newsEmailAddEditXHR.abort();
            } catch(e){
    
            }
            this.newsLetterContainer.querySelector('#newsletteremailadd').value = 'Load...';
            this.newsEmailAddEditXHR = new XMLHttpRequest();   // new HttpRequest instance 
           
            var POST_URL = '/api/newsletteremailadd';
            this.newsEmailAddEditXHR.open("POST", POST_URL);
            this.newsEmailAddEditXHR.setRequestHeader("Access-Control-Allow-Headers", "Origin,Content-Type,Accept");
            this.newsEmailAddEditXHR.setRequestHeader('Cache-Control', 'no-cache');
            
            var formData  = new FormData(newsLetterForm);
        
            formData.append('ismobile','N');
        
            let body = formData;
            this.newsEmailAddEditXHR.onreadystatechange = function() {
                if (this.newsEmailAddEditXHR.readyState === 4) {
                    try {
                        //this.newsEmailAddEditXHR.response // response
                        var r = JSON.parse(this.newsEmailAddEditXHR.responseText);
                        if(r.status == 0)
                        {
                            document.getElementById("contact_popup").innerHTML = '<p>'+r.message+'</p>';
                            document.getElementById("contact_popup").style.visibility="visible";
                            document.getElementById("contact_popup").style.top="10px";
                            setTimeout(this.hideNewsletterMessagePopup,3000); 
                        }
                        else
                        {
                            alert(r.message);
                        }
                    } catch(error) {
                        console.error(error);
                    }
                    newsletteremailEl.value = '';
                    this.newsLetterContainer.querySelector('#newsletteremailadd').value = 'Ok';
                }
            }
            .bind(this);
            this.newsEmailAddEditXHR.onabort = function() {   
            }
            this.newsEmailAddEditXHR.send(body);
            return this.newsEmailAddEditXHR;     
        }
    }

    ValidateEmail(mail) 
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
        return (false)
    }

    hideNewsletterMessagePopup()
    {
        document.getElementById("contact_popup").style.visibility="hidden";
        document.getElementById("contact_popup").style.top="-10px"
    }
}
const common = new FrontCommon();