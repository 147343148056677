class FrontCaseDetails {
    constructor(){
    }
    setup(){

        this.mostViewDataXHR = {};
        this.caseDetailsDataXHR = {};
        this.mostViewContainer = '';
        this.caseDetailsContainer = '';
        this.keyPointContainer = '';
        this.caseAlias = '';
        this.caseId = '';

        this.searchUrl = '';
        this.searchTitle = '';
        this.searchPrivacyId = [];
        this.searchCountryId = [];
        this.searchDocumentType = '';
        this.searchFromYear = '';
        this.searchToyear = '';
        this.searchUniqueId = '';
        
        if(document.querySelector('.detail-page-container'))
        {
            this.caseDetailsContainer = document.querySelector('.detail-page-container');
            this.searchUniqueId = this.caseDetailsContainer.getAttribute('data-searchuniqueid');  
            this.caseAlias = this.caseDetailsContainer.getAttribute('data-casealias');
            this.caseId = this.caseDetailsContainer.getAttribute('data-caseid');
            this.keyPointContainer = document.querySelector('.detail-page__keypoint');
            if(this.caseId > 0)
            {
                this.loadAndFileCaseDetails();
            }
            if(document.querySelector('.search-icon-btn'))
            {
                document.querySelector('.search-icon-btn').addEventListener('click', this.searchPageRedirect.bind(this));
            }
        }

        if(document.querySelector('.most-view-container'))
        {
            this.mostViewContainer = document.querySelector('.most-view-container');
            if(this.mostViewContainer.querySelector('.case-details')){
                this.caseDetailsClone = this.mostViewContainer.querySelector('.case-details').cloneNode(true);
                this.mostViewContainer.querySelector('.case-details').remove();
                this.mostViewCaseDetailsContainer = this.mostViewContainer.querySelector('.cases-list');
            } 
            this.loadMostViewCases();
        }

        document.querySelector('#title').addEventListener('keyup', this.checkKeyCode.bind(this));
    }

    checkKeyCode(e)
    {
        if (e.keyCode === 13) 
        {
            this.searchPageRedirect(e);
        }
    }

    searchPageRedirect(e)
    {
        this.searchTitle = document.querySelector('#title').value;
        
        for (var option of document.querySelector('#privacyid').options) {
            if (option.selected) 
            {
                this.searchPrivacyId.push(option.value);
            }
        }

        for (var option of document.querySelector('#countryid').options) {
            if (option.selected) 
            {
                this.searchCountryId.push(option.value);
            }
        }
        this.searchDocumentType = document.querySelector('#documenttype').value;
        this.searchFromYear = document.querySelector('#fromyear').value;
        this.searchToyear = document.querySelector('#toyear').value;
        
        this.searchUrl = '?title='+this.searchTitle+'&privacyid='+this.searchPrivacyId+'&countryid='+this.searchCountryId+'&documenttype='+this.searchDocumentType+'&fromyear='+this.searchFromYear+'&toyear='+this.searchToyear+'';
        window.location.href = '/search'+this.searchUrl;
    }

    // Case Details Function Start

    loadAndFileCaseDetails()
    {
        try{
            this.caseDetailsDataXHR.abort();
        } catch(e){

        }
        document.querySelector('.view-detail--skeleton').classList.remove('view-detail--skeleton--hide');

        this.caseDetailsDataXHR = new XMLHttpRequest();   // new HttpRequest instance 

        var POST_URL = '/api/casedetails';
        this.caseDetailsDataXHR.open("POST", POST_URL);
        this.caseDetailsDataXHR.setRequestHeader("Access-Control-Allow-Headers", "Origin,Content-Type,Accept");
        this.caseDetailsDataXHR.setRequestHeader('Cache-Control', 'no-cache');
        
        var formData  = new FormData();
          
        formData.append('platform','Website');
        formData.append('caseid',this.caseId);
        
        let body = formData;
        this.caseDetailsDataXHR.onreadystatechange = function() {
            if (this.caseDetailsDataXHR.readyState === 4) {
                try {
                    //this.caseDetailsDataXHR.response // response
                    var r = JSON.parse(this.caseDetailsDataXHR.responseText);
                    if(r.status == 0)
                    {
                        let caseDetails = r.data;
                        if(caseDetails !== null)
                        {   
                            let pdfUrl = '';
                            let prevCaseDetailsUrl = '';
                            let nextCaseDetailsUrl = '';
                            let yearSearchUrl = '/search?title=&privacyid=&countryid=&fromyear='+caseDetails.judgement_year+'&toyear='+caseDetails.judgement_year+'';
                            let countrySearchUrl = '/search?title=&privacyid=&countryid='+caseDetails.country_id+'&fromyear=&toyear=';
                            this.caseDetailsContainer.querySelector('.title').innerHTML = caseDetails.title;
                            this.caseDetailsContainer.querySelector('.detail-page__header__left__year--year').innerHTML = caseDetails.judgement_year+' - ';
                            this.caseDetailsContainer.querySelector('.detail-page__header__left__year--country').innerHTML = caseDetails.countryname;
                            this.caseDetailsContainer.querySelector('.detail-page__header__left__year--year').setAttribute('onclick', "window.open('" + yearSearchUrl + "')");
                            this.caseDetailsContainer.querySelector('.detail-page__header__left__year--country').setAttribute('onclick', "window.open('" + countrySearchUrl + "')");
                            this.caseDetailsContainer.querySelector('.detail-page__content').innerHTML = caseDetails.raw_content; 
                            if(caseDetails.pdfurl != '')
                            {
                                this.caseDetailsContainer.querySelector('.pdf-btn').style.display = 'flex'; 
                                pdfUrl = 'https://nluwebsite.s3.ap-south-1.amazonaws.com/'+caseDetails.pdfurl;
                                this.caseDetailsContainer.querySelector('.pdf-btn').setAttribute('onclick', "window.open('" + pdfUrl + "')");
                            }
                            if(caseDetails.judgment_hyperlink != '')
                            {
                                
                                if(caseDetails.judgment_hyperlink_text != '')
                                {
                                    this.caseDetailsContainer.querySelector('.document-hyperlink').innerHTML = caseDetails.judgment_hyperlink_text;
                                }
                                else
                                {
                                    this.caseDetailsContainer.querySelector('.document-hyperlink').innerHTML = 'Click here to view complete judgment';
                                }
                                this.caseDetailsContainer.querySelector('.document-hyperlink').style.display = "inline-block";
                                this.caseDetailsContainer.querySelector('.document-hyperlink').setAttribute('onclick', "window.open('" + caseDetails.judgment_hyperlink + "')");
                            }
                            if(caseDetails.judgement_year != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--judgement-year p').innerHTML = caseDetails.judgement_year;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--judgement-year').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.countryname != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--jurisdiction p').innerHTML = caseDetails.countryname;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--jurisdiction').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.relevant_constitutional_provision != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--legal-provision p').innerHTML = caseDetails.relevant_constitutional_provision;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--legal-provision').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.bench_strength != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--bench-strength p').innerHTML = caseDetails.bench_strength;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--bench-strength').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.typename != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--case-type p').innerHTML = caseDetails.typename;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--case-type').classList.remove('detail-page__keypoint__data--hide');
                            }
                            
                            if(caseDetails.case_status != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--case-status p').innerHTML = caseDetails.case_status;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--case-status').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.no_of_opinions != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--opinions p').innerHTML = caseDetails.no_of_opinions;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--opinions').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.case_citation != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--case-citation p').innerHTML = caseDetails.case_citation;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--case-citation').classList.remove('detail-page__keypoint__data--hide');
                            }
                            if(caseDetails.privacyname != '') {
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--privacy p').innerHTML = caseDetails.privacyname;
                                this.keyPointContainer.querySelector('.detail-page__keypoint__data--privacy').classList.remove('detail-page__keypoint__data--hide');
                            }
                            
                            if(this.searchUniqueId != '' && this.searchUniqueId == localStorage.getItem('searchuniqueid'))
                            {
                                var searchAlias = JSON.parse(localStorage.getItem("searchalias"));
                                if(searchAlias.length > 1)
                                {
                                    let aliasKey = this.arraySearch(searchAlias,this.caseAlias);
                                    document.querySelector('.prev-button').style.display = "none";
                                    document.querySelector('.next-button').style.display = "none";
                                    
                                    if(aliasKey > 0)
                                    {
                                        prevCaseDetailsUrl = '/case/'+searchAlias[aliasKey-1]+'?searchuniqueid='+this.searchUniqueId;
                                        document.querySelector('.prev-button').style.display = "flex";
                                        document.querySelector('.prev-button').setAttribute('onclick','window.location.href = "'+prevCaseDetailsUrl+'"');
                                    }
                                    if(aliasKey < (searchAlias.length)-1)
                                    {
                                        nextCaseDetailsUrl = '/case/'+searchAlias[aliasKey+1]+'?searchuniqueid='+this.searchUniqueId;
                                        document.querySelector('.next-button').style.display = "flex";
                                        document.querySelector('.next-button').setAttribute('onclick','window.location.href = "'+nextCaseDetailsUrl+'"');
                                    }
                                    document.querySelector('.prev-next-container').style.display = "flex";
                                }
                            } 
                        }
                    }
                    else
                    {
                    }

                } catch(error) {
                    console.error(error);
                }
                document.querySelector('.view-detail--skeleton').classList.add('view-detail--skeleton--hide');
            }
        }
        .bind(this);
        this.caseDetailsDataXHR.onabort = function() {   
        }
        this.caseDetailsDataXHR.send(body);
        return this.caseDetailsDataXHR;
    }

    arraySearch(arr,val) {
        for (var i=0; i<arr.length; i++)
            if (arr[i] === val)                    
                return i;
        return false;
    }
    // Case Details Function End

    //Most View Case list Function start

    loadMostViewCases()
    {
        try{
            this.mostViewDataXHR.abort();
        } catch(e){

        }
        this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.remove('cases-list--skeleton-list--hide');

        this.mostViewDataXHR = new XMLHttpRequest();   // new HttpRequest instance 

        var POST_URL = '/api/mostviewcases';
        this.mostViewDataXHR.open("POST", POST_URL);
        this.mostViewDataXHR.setRequestHeader("Access-Control-Allow-Headers", "Origin,Content-Type,Accept");
        this.mostViewDataXHR.setRequestHeader('Cache-Control', 'no-cache');
        
        var formData  = new FormData();
          
        formData.append('platform','Website');
        formData.append('pagenumber',this.pageNumber);
        if(this.searchValue != '')
        {
            formData.append('searchvalue',this.searchValue);
        }
        else 
        {
            formData.append('searchvalue','');
        }

        let body = formData;
        this.mostViewDataXHR.onreadystatechange = function() {
            if (this.mostViewDataXHR.readyState === 4) {
                try {
                    //this.mostViewDataXHR.response // response
                    var r = JSON.parse(this.mostViewDataXHR.responseText);
                    if(r.status == 0)
                    {
                        if(r.data.length > 0)
                        {
                            this.fillCaseData(r);
                        }
                        else
                        {
                            this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.add('cases-list--skeleton-list--hide');
                            this.mostViewCaseDetailsContainer.innerHTML = '<p class="feed-nomatches-cound">No matches.</p>';
                        }
                    }
                    else
                    {
                        alert(r.message);
                        this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.add('cases-list--skeleton-list--hide');
                    }

                } catch(error) {
                    console.error(error);
                }
            }
        }
        .bind(this);
        this.mostViewDataXHR.onabort = function() {   
        }
        this.mostViewDataXHR.send(body);
        return this.mostViewDataXHR;
    }

    fillCaseData(response)
    {
        
        for(const el of this.mostViewContainer.querySelectorAll('.case-details')){
            el.remove();
        }
        //this.mostViewCaseDetailsContainer.innerHTML = "";
    
        var c = 0;
        for(const caseData of response.data){
            if(caseData !== null){
                c++;
                
                const caseDataEl = this.caseDetailsClone.cloneNode(true);
                //caseDataEl.setAttribute('onclick','window.location.href = "/case/'+caseData.alias+'"');
                caseDataEl.setAttribute('href','/case/'+caseData.alias+'');
                caseDataEl.querySelector('.most-view__row__box__title').innerHTML = caseData.title;
                caseDataEl.querySelector('.most-view__row__box__year--year').innerHTML = caseData.judgement_year+' - ';
                caseDataEl.querySelector('.most-view__row__box__year--country').innerHTML = caseData.countryname;
                caseDataEl.querySelector('.most-view__row__box__content').innerHTML = caseData.description;
                this.mostViewCaseDetailsContainer.appendChild(caseDataEl);
            }
        }
        
        if(c == 0)
        {
            this.mostViewCaseDetailsContainer.innerHTML = '<p class="feed-nomatches-cound">No matches.</p>';
        }
        this.mostViewContainer.querySelector('.cases-list--skeleton-list').classList.add('cases-list--skeleton-list--hide');
    }
}
window.casedetails = new FrontCaseDetails();