class FrontSearch {
    constructor(){
    }
    setup(){

        this.searchDataXHR = {};
        this.searchContainer = '';
        this.searchTitleContainer = '';
        
        this.searchUrl = '';
        this.searchTitle = '';
        this.searchPrivacyId = [];
        this.searchPrivacyName = '';
        this.searchCountryId = [];
        this.searchCountryName = '';
        this.searchDocumentType = '';
        this.searchDocumentTypeName = '';
        this.searchFromYear = '';
        this.searchToyear = '';
        this.pageTitle = '';
        this.pageDescription = '';

        this.searchUniqueId = '';
        this.searchAlias = [];

        this.pageNumber = 1;

        if(document.querySelector('.container--search-title'))
        {
            this.searchTitleContainer = document.querySelector('.container--search-title');
        }

        if(document.querySelector('.search-page'))
        {
            this.searchContainer = document.querySelector('.search-page');
        
            this.searchTitle = this.searchContainer.getAttribute('data-title');
            this.searchDocumentType = this.searchContainer.getAttribute('data-documenttype');
            this.searchDocumentTypeName = this.searchContainer.getAttribute('data-documenttypename');
            for (var option of document.querySelector('#privacyid').options) {
                if (option.selected) 
                {
                    this.searchPrivacyId.push(option.value);
                }
            }
            this.searchPrivacyName = this.searchContainer.getAttribute('data-privacyname');
            for (var option of document.querySelector('#countryid').options) {
                if (option.selected) 
                {
                    this.searchCountryId.push(option.value);
                }
            }
            this.searchCountryName = this.searchContainer.getAttribute('data-countryname');
            this.searchFromYear = this.searchContainer.getAttribute('data-fromyear');
            this.searchToYear = this.searchContainer.getAttribute('data-toyear');
            if(this.searchContainer.querySelector('.case-details')){
                this.caseDetailsClone = this.searchContainer.querySelector('.case-details').cloneNode(true);
                this.searchContainer.querySelector('.case-details').remove();
                this.mostViewCaseDetailsContainer = this.searchContainer.querySelector('.cases-list');
            } 
            this.loadCases();
        }

        if(document.querySelector('.search-icon-btn'))
        {
            document.querySelector('.search-icon-btn').addEventListener('click', this.searchPageRedirect.bind(this));
        } 

        document.querySelector('#title').addEventListener('keyup', this.checkKeyCode.bind(this));
    }

    checkKeyCode(e)
    {
        if (e.keyCode === 13) 
        {
            this.searchPageRedirect(e);
        }
    }

    searchPageRedirect(e)
    {
        this.searchPrivacyId = [];
        this.searchCountryId = [];
        this.searchTitle = document.querySelector('#title').value;
        
        for (var option of document.querySelector('#privacyid').options) {
            if (option.selected) 
            {
                this.searchPrivacyId.push(option.value);
            }
        }
        for (var option of document.querySelector('#countryid').options) {
            if (option.selected) 
            {
                this.searchCountryId.push(option.value);
            }
        }
        this.searchDocumentType = document.querySelector('#documenttype').value;
        this.searchFromYear = document.querySelector('#fromyear').value;
        this.searchToyear = document.querySelector('#toyear').value;
        
        this.searchUrl = '?title='+this.searchTitle+'&privacyid='+this.searchPrivacyId+'&countryid='+this.searchCountryId+'&documenttype='+this.searchDocumentType+'&fromyear='+this.searchFromYear+'&toyear='+this.searchToyear+'';
        window.location.href = '/search'+this.searchUrl;
    }
    //City list Function start

    loadCases()
    {
        try{
            this.searchDataXHR.abort();
        } catch(e){

        }
        this.searchContainer.querySelector('.search-list--skeleton-list-container').classList.remove('search-result--skeleton-list--hide');
        this.searchDataXHR = new XMLHttpRequest();   // new HttpRequest instance 

        var POST_URL = '/api/cases';
        this.searchDataXHR.open("POST", POST_URL);
        this.searchDataXHR.setRequestHeader("Access-Control-Allow-Headers", "Origin,Content-Type,Accept");
        this.searchDataXHR.setRequestHeader('Cache-Control', 'no-cache');
        
        var formData  = new FormData();
          
        formData.append('platform','Website');

        formData.append('searchtitle',this.searchTitle);
        formData.append('searchdocumentid',this.searchDocumentId);
        formData.append('searchprivacyid',this.searchPrivacyId);
        formData.append('searchcountryid',this.searchCountryId);
        formData.append('searchfromyear',this.searchFromYear);
        formData.append('searchtoyear',this.searchToYear);
        formData.append('searchdocumenttype',this.searchDocumentType);
        formData.append('pagenumber',this.pageNumber);

        let body = formData;
        this.searchDataXHR.onreadystatechange = function() {
            if (this.searchDataXHR.readyState === 4) {
                try {
                    //this.searchDataXHR.response // response
                    var r = JSON.parse(this.searchDataXHR.responseText);

                    if(r.status == 0)
                    {
                        if(r.data.length > 0)
                        {

                            this.pageTitle = r.totalrecords+' documents found';
                            //[N] [documents or document type] found for [aspect of privacy] in [jurisdiction] between [year] and [year]. 
                            this.pageDescription = r.totalrecords+' documents. Privacy Law Library';
                            

                            if(this.searchDocumentTypeName != '' && this.searchPrivacyName != '' && this.searchCountryName != '')
                            {
                                this.pageTitle = r.totalrecords+' '+this.searchDocumentTypeName+' found for '+this.searchPrivacyName+' in '+this.searchCountryName;
                                this.pageDescription = r.totalrecords+' '+this.searchDocumentTypeName+' found for '+this.searchPrivacyName+' in '+this.searchCountryName+' between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            else if(this.searchDocumentTypeName != '' && this.searchPrivacyName != '')
                            {
                                this.pageTitle = r.totalrecords+' '+this.searchDocumentTypeName+' found for '+this.searchPrivacyName;
                                this.pageDescription = r.totalrecords+' '+this.searchDocumentTypeName+' found for '+this.searchPrivacyName+' between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            else if(this.searchDocumentTypeName != '' && this.searchCountryName != '')
                            {
                                this.pageTitle = r.totalrecords+' '+this.searchDocumentTypeName+' found in '+this.searchCountryName;
                                this.pageDescription = r.totalrecords+' '+this.searchDocumentTypeName+' found in '+this.searchCountryName+' between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            else if(this.searchPrivacyName != '' && this.searchCountryName != '')
                            {
                                this.pageTitle = r.totalrecords+' documents found for '+this.searchPrivacyName+' in '+this.searchCountryName;
                                this.pageDescription = r.totalrecords+' documents found for '+this.searchPrivacyName+' in '+this.searchCountryName+' between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            else if(this.searchDocumentTypeName != '')
                            {
                                this.pageTitle = r.totalrecords+' '+this.searchDocumentTypeName+' found';
                                this.pageDescription = r.totalrecords+' '+this.searchDocumentTypeName+' found between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            else if(this.searchPrivacyName != '')
                            {
                                this.pageTitle = r.totalrecords+' documents found for '+this.searchPrivacyName;
                                this.pageDescription = r.totalrecords+' documents found for '+this.searchPrivacyName+' between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            else if(this.searchCountryName != '')
                            {
                                this.pageTitle = r.totalrecords+' documents found in '+this.searchCountryName;
                                this.pageDescription = r.totalrecords+' documents found in '+this.searchCountryName+' between '+this.searchFromYear+' and '+this.searchToYear;
                            }
                            
                            document.title = this.pageTitle;
                            document.querySelector('meta[name="description"]').setAttribute("content",this.pageDescription);

                            if(this.searchTitle != '' || this.searchDocumentId != '' || this.searchPrivacyId != '' || this.searchCountryId != '' || this.searchFromYear != '' || this.searchToYear != '' || this.searchDocumentType != '')
                            {
                                this.searchUniqueId = Math.floor(100000 + Math.random() * 900000);
                            }

                            this.fillCaseData(r);
                            if(r.totalrecords > 0)
                            {
                                this.totalResultText = r.totalrecords+' results found';
                                if(this.searchTitle != '')
                                {
                                    this.totalResultText = r.totalrecords+' results for '+'<span>"'+this.searchTitle+'"</span>';
                                }
                            }
                            this.searchTitleContainer.querySelector('.title').innerHTML = this.totalResultText;
                        }
                        else
                        {
                            this.searchContainer.querySelector('.search-list--skeleton-list-container').classList.remove('search-result--skeleton-list--hide');
                            this.mostViewCaseDetailsContainer.innerHTML = '<p class="feed-nomatches-cound">No matches.</p>'; 
                        }
                    }
                    else
                    {
                        alert(r.message);
                        this.searchContainer.querySelector('.search-list--skeleton-list-container').classList.remove('search-result--skeleton-list--hide');
                    }

                } catch(error) {
                    console.error(error);
                }
            }
        }
        .bind(this);
        this.searchDataXHR.onabort = function() {   
        }
        this.searchDataXHR.send(body);
        return this.searchDataXHR;
    }

    fillCaseData(response)
    {
        
        for(const el of this.searchContainer.querySelectorAll('.case-details')){
            el.remove();
        }
        //this.mostViewCaseDetailsContainer.innerHTML = "";
    
        var c = 0;
       
        for(const caseData of response.data)
        {
            if(caseData !== null)
            {
                c++;

                let pdfUrl = '';
                let yearSearchUrl = 'search?title=&privacyid=&countryid=&fromyear='+caseData.judgement_year+'&toyear='+caseData.judgement_year+'';
                let countrySearchUrl = 'search?title=&privacyid=&countryid='+caseData.country_id+'&fromyear=&toyear=';
                let privacySearchUrl = 'search?title=&privacyid='+caseData.privacy_id+'&countryid=&fromyear=&toyear=';
                let caseDetailsUrl = "";
                if(caseData.identy_type == "case")
                {
                    caseDetailsUrl = '/case/'+caseData.alias+'';
                    if(this.searchUniqueId != '')
                    {
                        this.searchAlias.push(caseData.alias);
                        caseDetailsUrl = '/case/'+caseData.alias+'?searchuniqueid='+this.searchUniqueId;
                    }
                }
                const caseDataEl = this.caseDetailsClone.cloneNode(true); 

                if(caseData.identy_type == "case")
                {
                    caseDataEl.querySelector('.small-title').setAttribute('onclick','window.location.href = "'+caseDetailsUrl+'"');
                    caseDataEl.querySelector('.read-more').setAttribute('onclick','window.location.href = "'+caseDetailsUrl+'"');
                    caseDataEl.querySelector('.small-title').innerHTML = caseData.title;
                    caseDataEl.querySelector('.case-details--year').innerHTML = caseData.judgement_year+ (caseData.privacyname != "" ? " - " : "");
                    caseDataEl.querySelector('.case-details--country').innerHTML = caseData.privacyname;
                    caseDataEl.querySelector('.case-details--year').setAttribute('onclick', "window.open('" + yearSearchUrl + "')");
                    caseDataEl.querySelector('.case-details--country').setAttribute('onclick', "window.open('" + privacySearchUrl + "')");
                    

                    caseDataEl.querySelector('.description').innerHTML = caseData.description;
                    if(caseData.raw_content != ""){
                        caseDataEl.querySelector('.description--raw').innerHTML = caseData.raw_content;
                    } else {
                        caseDataEl.querySelector('.description--raw').remove();
                    }

                    if(caseData.pdfurl != '')
                    {
                        pdfUrl = caseData.pdfurl;
                        caseDataEl.querySelector('.download-btn').style.display = "inline-block";
                        caseDataEl.querySelector('.download-btn').setAttribute('onclick', "window.open('" + pdfUrl + "')");
                    }
                }
                else 
                {
                    caseDetailsUrl = '/case-tracker/'+caseData.alias+'';
                    if(this.searchUniqueId != '')
                    {
                        this.searchAlias.push(caseData.alias);
                        caseDetailsUrl = '/case-tracker/'+caseData.alias+'?searchuniqueid='+this.searchUniqueId;
                    }
                    caseDataEl.querySelector('.small-title').innerHTML = caseData.title + ' (High Court Tracker)';
                    caseDataEl.querySelector('.small-title').setAttribute('onclick','window.location.href = "'+caseDetailsUrl+'"');
                    caseDataEl.querySelector('.read-more').setAttribute('onclick','window.location.href = "'+caseDetailsUrl+'"');

                    //caseDataEl.querySelector('.small-title').setAttribute('onclick', "window.open('" + caseDetailsUrl + "')");
                    //caseDataEl.querySelector('.read-more').setAttribute('onclick', "window.open('" + caseDetailsUrl + "')");
                    
                    caseDataEl.querySelector('.case-details--year').innerHTML = caseData.judgement_year+ (caseData.privacyname != "" ? " - " : "");
                    caseDataEl.querySelector('.case-details--country').innerHTML = caseData.privacyname;
                    caseDataEl.querySelector('.case-details--year').setAttribute('onclick', "window.open('" + yearSearchUrl + "')");
                    caseDataEl.querySelector('.description').innerHTML = caseData.description;
                }
                this.mostViewCaseDetailsContainer.appendChild(caseDataEl);
            }
        }
        
        if(this.searchuniqueid != '' && this.searchAlias.length > 1)
        {
            localStorage.setItem("searchuniqueid",this.searchUniqueId);
            localStorage.setItem("searchalias",JSON.stringify(this.searchAlias));
        }

        if(c == 0)
        {
            this.mostViewCaseDetailsContainer.innerHTML = '<p class="feed-nomatches-cound">No matches.</p>';
        }
        this.searchContainer.querySelector('.search-list--skeleton-list-container').classList.add('search-result--skeleton-list--hide');
    }
    //City list Function end
}
window.search = new FrontSearch();