class FrontContact {
    constructor(){
    }
    setup(){

        this.contactAddEditXHR = {};
        this.contactMainContainer = '';
     
        if(document.querySelector('.container--contact'))
        {
            this.contactMainContainer = document.querySelector('.container--contact');
            this.contactMainContainer.querySelector('#sendmessage').addEventListener('click', this.contactAddEdit.bind(this));
        }
    }

    contactAddEdit(e)
    {
        var nameEl = this.contactMainContainer.querySelector('#name');
        var phoneEl = this.contactMainContainer.querySelector('#phone');
        var messageEl = this.contactMainContainer.querySelector('#message');
        
        if(nameEl.value == '')
        {
            nameEl.setAttribute('data-invalid','');
        }
        else if(phoneEl.value == '' || !this.validatePhone(phoneEl.value))
        {
            phoneEl.setAttribute('data-invalid','');
        }
        else if(messageEl.value == '')
        {
            messageEl.setAttribute('data-invalid','');
        }
        else
        {
            try{
                this.contactAddEditXHR.abort();
            } catch(e){
    
            }
            this.contactMainContainer.querySelector('#sendmessage').innerHTML = 'Loading...';
            this.contactAddEditXHR = new XMLHttpRequest();   // new HttpRequest instance 
           
            var POST_URL = '/api/contactaddedit';
            this.contactAddEditXHR.open("POST", POST_URL);
            this.contactAddEditXHR.setRequestHeader("Access-Control-Allow-Headers", "Origin,Content-Type,Accept");
            this.contactAddEditXHR.setRequestHeader('Cache-Control', 'no-cache');
            
            var formData  = new FormData(contactForm);
        
            formData.append('ismobile','N');
        
            let body = formData;
            this.contactAddEditXHR.onreadystatechange = function() {
                if (this.contactAddEditXHR.readyState === 4) {
                    try {
                        //this.contactAddEditXHR.response // response
                        var r = JSON.parse(this.contactAddEditXHR.responseText);
                        
                        if(r.status == 0)
                        {
                            document.getElementById("contact_popup").innerHTML = '<p>'+r.message+'</p>';
                            document.getElementById("contact_popup").style.visibility="visible";
                            document.getElementById("contact_popup").style.top="10px";
                            setTimeout(this.hideMessageAlertPopup,3000); 
                        }
                        else
                        {
                            alert(r.message);
                        }
                    } catch(error) {
                        console.error(error);
                    }
                    nameEl.value = '';
                    phoneEl.value = '';
                    messageEl.value = '';
                    this.contactMainContainer.querySelector('#sendmessage').innerHTML = 'Send message';
                }
            }
            .bind(this);
            this.contactAddEditXHR.onabort = function() {   
            }
            this.contactAddEditXHR.send(body);
            return this.contactAddEditXHR;     
        }
    }
    hideMessageAlertPopup()
    {
        document.getElementById("contact_popup").style.visibility="hidden";
        document.getElementById("contact_popup").style.top="-10px"
    }
    validatePhone(inputValue) 
    {
        if(inputValue.match(/^\d{10}/)) 
        {
            return true;
        } 
        return false;
    }
}
window.contact = new FrontContact();