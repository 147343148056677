class JetbroUtil {
    constructor() {
        this.VALIDATION_TYPES = {
            EMAIL: "EMAIL",
            STRING: "STRING",
            INTEGER: "INTEGER",
            FLOAT: "FLOAT",
            MOBILE: "MOBILE",
            URL: "URL",
            SLUG: "SLUG",
            DOMAIN: "DOMAIN",
            MINONE: "MINONE",
            GSTIN: "GSTIN",
            PINCODE: "PINCODE",
            TRUE: "TRUE"
        }
        this.KEY_DETECTED = {
            ESCAPE: "ESCAPE",
            ENTER: "ENTER",
            ALPHA: "ALPHA",
            NUMERIC: "NUMERIC",
            UNKOWN: "UNKOWN"
        }
        this.alert = "";
    }

    validateInputAndThrowError(value, validationType, elToFocus, message) {
        if (message === undefined) {
            message = "Invalid";
        }
        if (!this.validateInput(value, validationType)) {
            this.alert.errorMessage(message, "Okay", function () {
                if (elToFocus) { elToFocus.focus(); }
            }.bind(this));
            return false;
        }
        return true;
    }
    validateInput(value, validationType) {
        validationType = validationType.toUpperCase();
        switch (validationType) {
            case this.VALIDATION_TYPES.EMAIL:
                return this.validateEmail(value);
                break;

            case this.VALIDATION_TYPES.MOBILE:
                return this.validateMobile(value);
                break;

            case this.VALIDATION_TYPES.MINONE:
                return this.validateMinone(value);
                break;

            case this.VALIDATION_TYPES.INTEGER:
                return this.validateInteger(value);
                break;

            case this.VALIDATION_TYPES.FLOAT:
                return this.validateFloat(value);
                break;

            case this.VALIDATION_TYPES.TRUE:
                return this.validateTrue(value);
                break;
            case this.VALIDATION_TYPES.URL:
                return this.validateURL(value);
                break;
            case this.VALIDATION_TYPES.SLUG:
                return this.validateSlug(value);
                break;
            case this.VALIDATION_TYPES.DOMAIN:
                return this.validateDomain(value);
                break;
            case this.VALIDATION_TYPES.GSTIN:
                return this.validateGSTIN(value);
                break;
            case this.VALIDATION_TYPES.PINCODE:
                return this.validatePincode(value);
                break;

            default:
                return this.validateString(value);
                break;
        }
    }
    validateURL(value) {
        if (value == "") { return false; }
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(value);
    }
    validateGSTIN(value) {
        if (value == "") { return false; }
        var re = new RegExp(/^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/);
        return value.match(re);
    }
    validatePincode(value) {
        if (value == "") { return false; }
        var re = new RegExp(/^[1-9][0-9]{5}$/);
        return value.match(re);
    }
    validateDomain(value) {
        if (value == "") { return false; }
        var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
        return value.match(re);
    }
    validateSlug(value) {
        if (value == "") { return false; }
        return value == this.slugify(value);
    }
    validateFloat(value) {
        if (value == "") { return false; }
        return parseFloat(value) == value;
    }
    validateInteger(value) {
        if (value == "") { return false; }
        return parseInt(value) == value;
    }

    validateTrue(value) {
        if (value === true) {
            return true;
        } else {
            return false;
        }
    }
    validateMinone(value) {
        if (value.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    validateString(x) {
        if (this.safeTrim(x) == "") {
            return false;
        } else {
            return true;
        }
    }
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    validateMobile(mobile) {
        mobile = mobile.replace(/ /g, "");
        if (mobile.length > 10) {
            return false;
        }
        var re = /\d{10}/;
        return re.test(mobile);
    }

    safeTrim(x) {
        return x.replace(/^\s+|\s+$/gm, '');
    }

    loadHTML(url, callback) {
        var formData = new FormData();
        formData.append("loader", 1);
        var opts = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            redirect: "follow",
            referrer: "no-referrer",
            body: formData
        };
        fetch(url, opts)
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.status + " " + url);
                }
                return response.text()
            }).then(responseText => {
                callback(responseText);
            }).catch(error => {
                console.error(error);
            });
    }
    appendHTMLContentInEl(el, htmlContent) {
        let t = document.createElement("div");
        t.innerHTML = htmlContent;
        for (var c of t.children) {
            el.append(c.cloneNode(true));
        }
    }
    loadHTMLAndAppend(url, el) {
        return new Promise(function (resolve, _reject) {
            this.loadHTML(url, function (htmlContent) {
                this.appendHTMLContentInEl(el, htmlContent);
                resolve();
            }.bind(this));
        }.bind(this));
    }
    isEscapeKey(evt) {
        if (this.getKeyFromEvent(evt) == this.KEY_DETECTED.ESCAPE) {
            return true;
        } else {
            return false;
        }
    }
    isEnterKey(evt) {
        if (this.getKeyFromEvent(evt) == this.KEY_DETECTED.ENTER) {
            return true;
        } else {
            return false;
        }
    }

    isAlphaNumericKey(evt) {
        if (this.getKeyFromEvent(evt) == this.KEY_DETECTED.ALPHA || this.getKeyFromEvent(evt) == this.KEY_DETECTED.NUMERIC) {
            return true;
        } else {
            return false;
        }
    }

    getAlphaNumericSpecialKey(evt) {
        var inp = String.fromCharCode(evt.keyCode);
        if (/[a-zA-Z0-9-_ ]/.test(inp))
            return inp
        return ""
    }
    getKeyFromEvent(evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
            return this.KEY_DETECTED.ESCAPE;
        }
        var isEnter = false;
        if ("key" in evt) {
            isEnter = (evt.key === "Enter");
        } else {
            isEnter = (evt.keyCode === 13);
        }
        if (isEnter) {
            return this.KEY_DETECTED.ENTER;
        }
        if (evt.keyCode >= 48 && evt.keyCode <= 57)
            return this.KEY_DETECTED.NUMERIC;
        if (evt.keyCode >= 65 && evt.keyCode <= 90)
            return this.KEY_DETECTED.ALPHA;
        return this.KEY_DETECTED.UNKOWN;
    }
    api_call(url, data) {
        return new Promise((resolve, reject) => {

            var xhr = new XMLHttpRequest();   // new HttpRequest instance 
            xhr.withCredentials=true;
            xhr.open("POST", url);
            xhr.setRequestHeader('Cache-Control', 'no-cache');
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    if(xhr.status == 0){
                        return;
                    }
                    try {
                        let responseJSON = JSON.parse(xhr.response);
                        resolve({
                            statusCode: parseInt(responseJSON.statusCode),
                            statusMessage: responseJSON.statusMessage,
                            data: responseJSON.data
                        });
                    } catch(error) {
                        console.error(error);
                        if(xhr.status != 0){
                            
                        }
                    }
                }
            }
            xhr.send(JSON.stringify(data));
           
        });
    }
    toIndianMoneyFormat(x){
        x=x.toString();
        var lastThree = x.substring(x.length-3);
        var otherNumbers = x.substring(0,x.length-3);
        if(otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    }
}
const UtilObject = new JetbroUtil();
export default UtilObject;
global.Util = UtilObject;