import CookieConsent from "./_cookie-consent";
class NluWeb {
    constructor() {
        this.setup();
    }
    
    setup() {
        CookieConsent.initialize();
    }  
}
global.App = new NluWeb();
//const App = new NluWeb();

document.querySelector('.mobile-menu').addEventListener('click', function (e) {
    document.body.classList.toggle('menu-open');
});
for (let el of document.querySelectorAll('.header__container__menu a')) {
    el.addEventListener('click', function (e) {
        if (document.body.classList.contains('menu-open')) {
            document.body.classList.remove('menu-open');
        }
    })
  }
for (let el of document.querySelectorAll('.header__container__menu a')) {
    el.addEventListener('click', function () {
        for (let ela of document.querySelectorAll('.header__container__menu a')) {
        ela.classList.remove("active");
        }
        el.classList.add('active');
    })
}